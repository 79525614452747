.wrapper {
  @apply max-w-7xl p-6 mx-auto w-full overflow-hidden;
  padding-top: 3rem;
}
@media (max-width: theme("screens.md")) {
  .wrapper {
    @apply p-4;
  }
}
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  @apply !text-red;
}
.ant-menu-item-selected:after,
.ant-menu-submenu-selected:after {
  @apply !border-red;
}
.ant-menu-overflow-item {
  @apply !px-7;
}
.ant-menu-overflow-item:hover {
  @apply !text-red;
}
.ant-menu-overflow-item:hover .ant-menu-title-content {
  @apply !text-red;
}
.ant-menu-overflow-item:hover:after {
  @apply !border-red;
}
