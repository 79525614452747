@tailwind base;
@tailwind components;
@tailwind utilities;

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
  vertical-align: middle;
}

/* @media screen and (max-width: 600px) {
  body {
    overflow: auto !important;
    max-height: 100vh;
  }
} */
html {
  scroll-padding-top: 140px;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: var(--default-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: no-contextual;
}

body.mobile-top-nav-open {
  overflow: hidden !important;
}

@font-face {
  font-family: "TransSansPremium";
  src: url("./assets/fonts/TransSansPremium-Light.woff2") format("woff2"), url("./assets/fonts/TransSansPremium-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "TransSansPremium";
  src: url("./assets/fonts/TransSansPremium-Regular.woff2") format("woff2"), url("./assets/fonts/TransSansPremium-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "TransSansPremium";
  src: url("./assets/fonts/TransSansPremium-Medium.woff2") format("woff2"), url("./assets/fonts/TransSansPremium-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "TransSansPremium";
  src: url("./assets/fonts/TransSansPremium-SemiBold.woff2") format("woff2"), url("./assets/fonts/TransSansPremium-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "TransSansPremium";
  src: url("./assets/fonts/TransSansPremium-Bold.woff2") format("woff2"), url("./assets/fonts/TransSansPremium-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --swiper-theme-color: #62e6d4 !important;
  --swiper-pagination-progressbar-bg-color: #ffffff70;
  /* --swiper-pagination-bullet-inactive-color: #595959; */

  /* semibold/light never been used  */
  --default-font-family: "TransSansPremium", "system-ui", "BlinkMacSystemFont", "Roboto", "Helvetica Neue", "Segoe UI", "Arial", "sans-serif",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  /* same as scroll documnet */
  --developer-page-font-family: "Roboto", "trans-sans", "circular", Arial, "Helvetica Neue", Helvetica, sans-serif;
}
